<template>

	<!-- Authors Table Card -->
	<div>
		<div v-if="alert.msg">
			<a-alert :message="alert.msg" :type="alert.type" closable></a-alert>
		</div>
		<loading :active.sync="isLoading" :is-full-page="true"></loading>
		<a-card :bordered="false" class="header-solid h-full" :bodyStyle="{padding: 0,}">
			<template #title>
				<a-row type="flex" align="middle">
					<a-col :span="24" :md="12" class="mb-3">
						<h5 class="font-semibold m-0">Daftar Order</h5>
					</a-col>
					<a-col :span="24" :md="12" style="display: flex; align-items: center; justify-content: flex-end" class="mb-3">

						<a-button @click="showModal = true" size="small" style="margin-right: 10px;">
							<a-icon type="funnel-plot" theme="outlined" />
						    Filter Data
						</a-button>
						<a-button type="primary" @click="onClickCreateData()" size="small">
							<a-icon type="plus" theme="outlined" />
						    Buat Order
						</a-button>
					</a-col>
				</a-row>
			</template>
			<a-modal v-model="showModal" title="Filter Data" @ok="onSubmitFilter">
				<div class="mb-3">
		      		<a-input v-model="filter.keyword" placeholder="Masukkan kata kunci ..." />
					<small>No resi, nama pengirim, alamat pengirim, no kontak pengirim, nama penerima, alamat penerima, no kontak penerima, no alternatif penerima</small>
				</div>
				<div class="mb-3">
					<h6 class="font-regular">Filter status</h6>
					<div class="mb-1" v-for="(status, index) in statuses">
						<input type="checkbox" v-model="filter.status" :value="status" :key="`status_checkbox${index}`" :id="`status_checkbox${index}`">
						<label :for="`status_checkbox${index}`"> {{status}}</label>
					</div><br/>
				</div>
		    </a-modal>
		    <a-modal v-model="showModalDelete" title="Hapus Data" @ok="onSubmitDelete">
				<div class="mb-3">
					<h6 class="text-center">Order #{{selected_row.unique_id}}</h6>
		      		<a-alert message="Anda akan menghapus order yang dipilih. Apakah anda yakin?" type="error" />
				</div>
		    </a-modal>
		</a-card>
		<br/>
		<a-card :bordered="false" class="header-solid mb-2" :bodyStyle="{padding: 0,}" v-for="(order, index) in data" :key="`order_item_${index}`">
			<template #title>
				<a-row type="flex" align="middle">
					<a-col :span="24" :md="12" class="mb-3">
						<h6 class="font-semibold m-0">
							<span :style="renderColor(order.status)">{{order.status}}</span> | #{{order.unique_id}}
						</h6>
					</a-col>
					<a-col :span="24" :md="12" style="display: flex; align-items: center; justify-content: flex-end" class="mb-3">
						<a-button @click="onClickUpdateOrder(order)" size="small" style="margin-right: 10px;">
							<a-icon type="edit" theme="outlined" />
						    Ubah
						</a-button>
						<a-button type="danger" ghost @click="onDelete(order)" size="small" style="margin-right: 10px;">
							<a-icon type="close-circle" theme="outlined" />
						    Cancel
						</a-button>
						<a-button title="Cetak Resi" icon="qrcode" shape="circle" @click="printWaybill(order)" size="small" style="margin-right: 10px;"></a-button>
						<a-button title="Tracking" type="primary" icon="environment" shape="circle" @click="gotoTracking(order)" size="small"></a-button>
					</a-col>
				</a-row>
			</template>
			<a-row :gutter="24" type="flex" class="p-2">
				<a-col class="mb-2" :span="12" :lg="6" :md="6" :sm="12" style="padding-left: 15px; padding-right: 15px;">
					<div class="font-bold">Tanggal Order</div>
					<div class="mb-1">{{order.order_date ? parseDate(order.order_date) : '--'}}</div>

					<div class="font-bold">Dibuat Oleh</div>
					<div class="mb-1">{{order.created_by.fullname}} tgl {{parseDate(order.created_at)}} </div>

					<div class="font-bold">Update Oleh</div>
					<div class="mb-1">{{order.histories[order.histories.length - 1].created_by}} tgl {{parseDate(order.histories[order.histories.length - 1].created_at)}}</div>
				</a-col>
				<a-col class="mb-2" :div="12" :lg="6" :md="6" :sm="12" style="padding-left: 15px; padding-right: 15px;">
					<div class="font-bold">Nama Pengirim</div>
					<div class="mb-1">{{order.sender_name ? order.sender_name : '--'}}</div>

					<div class="font-bold">Alamat</div>
					<div class="mb-1">{{order.sender_addr ? order.sender_addr : '--'}}</div>

					<div class="font-bold">No Kontak</div>
					<div class="mb-1">{{order.sender_contact ? order.sender_contact : '--'}}</div>
				</a-col>
				<a-col class="mb-2" :span="12" :lg="6" :md="6" :sm="12" style="padding-left: 15px; padding-right: 15px;">
					<div class="font-bold">Nama Penerima</div>
					<div class="mb-1">{{order.receiver_name ? order.receiver_name : '--'}}</div>

					<div class="font-bold">Alamat</div>
					<div class="mb-1">{{order.receiver_addr ? order.receiver_addr : '--'}}</div>

					<div class="font-bold">No Kontak / Alternatif</div>
					<div class="mb-1">{{order.receiver_contact ? order.receiver_contact : '--'}} / {{order.receiver_contact_alt ? order.receiver_contact_alt : '--'}}</div>
				</a-col>
				<a-col class="mb-2" :span="12" :lg="6" :md="6" :sm="12" style="padding-left: 15px; padding-right: 15px;">
					<div class="font-bold">Pembayaran</div>
					<div class="mb-1" v-if="!order.is_paid" style="color: firebrick;">Belum bayar</div>
					<div class="mb-1" v-if="order.is_paid"><strong>{{order.payment_type}}</strong></div>

					<div class="font-bold">Volume / Berat</div>
					<span class="mb-1">{{order.volume ? order.volume : '--'}} m<sup>3</sup></span> / 
					<span class="mb-1">{{order.weight ? order.weight : '--'}} kg</span>

					<a-popover title="Detil Harga" trigger="hover">
				      	<template slot="content">
				      		<div v-if="order.prices.length > 0">
					        	<p class="mb-0" v-for="(price, index) in order.prices">{{price.name}}: {{price.price_text}}</p>
				      		</div>
				      		<div v-else>
				      			<p class="mb-0">Tidak ada data</p>
				      		</div>
				      	</template>
			        	<div class="font-bold">Total Harga</div>
						<div class="mb-1">{{order.total_price_text}}</div>
				    </a-popover>
				</a-col>
			</a-row>
		</a-card>
		<a-card class="header-solid h-full" :bodyStyle="{padding: 0,}">
			<template #title>
				<a-row type="flex" align="middle">
					<a-col :span="24" style="display: flex; align-items: center; justify-content: flex-end;" class="mb-3">
						<a-pagination v-model="filter.page" :pageSize="filter.paginate_count" :total="filter.total" />
						<br/>
					</a-col>
				</a-row>
			</template>
		</a-card>
	</div>
	<!-- / Authors Table Card -->

</template>

<script>
	import { processMessage, setInitialValue, renderStatusColor } from '../helpers';
	import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';

	export default ({
		data() {
			return {
				// Active button for the "Authors" table's card header radio button group.
				authorsHeaderBtns: 'all',
				data: [],
				statuses: [],
				filter: {
					keyword: '',
					status: [],
					page: 1,
					paginate_count: 20,
					total: 0,
				},
				showModal: false,
				showModalDelete: false,
				selected_row: {},
				alert: {
					type: '',
					msg: ''
				},
				isLoading: false
			}
		},
		mounted(){
			this.retrieveOrderList()
			this.retrieveStatusIndex()
		},
		components: { Loading },
	 	methods: {
	 		parseDate(datetime){
	 			return this.$moment(datetime).tz('Asia/Jakarta').format('Do MMM YYYY HH:mm')
	 		},
	 		renderColor(status){
	 			return renderStatusColor(status)
	 		},
	 		retrieveOrderList(){
	 			this.isLoading = true
	 			this.$http.get(process.env.VUE_APP_API + '/order?keyword=' + this.filter.keyword + '&status=' + this.filter.status + '&page=' + this.filter.page + '&paginate_count=' + this.filter.paginate_count)
				.then((res) => {
					this.data = res.data.data
					console.log(this.data)
					this.filter.total = res.data.meta.total
				})
				.catch(err => {
					this.alert = processMessage(err.response)
				})
				.finally(() => {
					this.isLoading = false
				})
	 		},
	 		retrieveStatusIndex(){
	 			this.$http.get(process.env.VUE_APP_API + '/status/index')
				.then((res) => {
					this.statuses = res.data.data
				})
				.catch(err => {
					this.alert = processMessage(err.response)
				})
	 		},
			onSubmitFilter(e){
      			this.showModal = false;
				this.retrieveOrderList();
			},
			onDelete(row){
				this.showModalDelete = true 
				this.selected_row = row
			},
			onSubmitDelete(){
				this.isLoading = true
				this.$http.delete(process.env.VUE_APP_API + '/price/' + this.selected_row.id)
				.then((res) => {
					this.alert = processMessage(res)
					this.showModalDelete = false
					this.retrievePriceList()
				})
				.catch(err => {
					this.alert = processMessage(err.response)
				})
				.finally(() => {
					this.isLoading = false
				})
			},
			goToTracking(order){

			},
			printWaybill(order){
				return window.open(process.env.VUE_APP_SERVER + '/generate/waybill/' + order.unique_id, '_blank')
			},
			onClickCreateData(){
				this.$router.push({name: 'Buat Order'})
			},
			onClickUpdateOrder(order){
				this.$router.push({name: 'Edit Order', params: { id: order.id }})
			}
		}
	})

</script>